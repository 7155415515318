import React, { useEffect, useState } from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import '../../styles/custome.css';
import axios from "axios"


export default function Channels() {
    const [channels, setChannels] = useState([{"channel_number": 0, "name": "Loading...", "packages": {"Loading...": true}}]);
    const [columns, setColumns] = useState(["Loading..."]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios(
                  "https://product-catalog-storage.s3.ca-central-1.amazonaws.com/snapshots/latest/services/tv/channel_guide.json"
                );
                setColumns(Object.keys(response.data[0].packages));
                setChannels(response.data);
            } catch (err) {
                console.error("Failed to fetch channels:", err);
            }
        };
        fetchData();

    }, []);

  return (
      <LayoutNew>
          <Helmet>
              <title>Channels Guide – Purple Cow Internet 💜🐄</title>
          </Helmet>
          <section className="px-4 py-8 mx-auto font-light prose prose-lg" style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%'
          }}>
              <h1>TV Channels</h1>
              <p className="font-bold">
                  This is a list of all the channels we currently offer. If you have any questions, don't
                  hesitate to
                  call
                  or text us at 902-800-2660.
              </p>
              <div className={"table-container"}>
                  <table>
                      <thead>
                      <tr>
                          <th>Channel #</th>
                          <th>Channel Name</th>
                          {columns.map((header) => (
                              <th>{header}</th>
                          ))}
                      </tr>
                      </thead>
                      <tbody>
                      {channels.map((channel, index) => (
                          <tr key={index}>
                              <td>{channel.channel_number}</td>
                              <td>{channel.name}</td>
                              {columns.map((header) => (
                                  <td>{channel.packages[header] ? '✓' : ''}</td>
                              ))}
                          </tr>
                      ))}
                      </tbody>
                  </table>
              </div>
          </section>
          <p style={{textAlign: 'center', color: '#666', marginTop: '10px', marginBottom: '20px'}}>
              Last Updated: June 11th 2024
          </p>
      </LayoutNew>
  )
}
